import { del, get, post, postFile } from "./api_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const token = localStorage.getItem("token")
  if (token) return token
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Auth Methods
const postJwtLogin = data => post("login/authenticate", data)
const postJwtLoginNewApi = data => post("users/login", data, {}, true)
const lostPassword = data => post("login/LostPassword", data)
const lostPasswordNewApi = data => post("users/requestPassword", data, {}, true)
const recoverPassword = data => post("login/RecoverPassword", data)
const recoverPasswordNewApi = data =>
  post("users/recoverPassword", data, {}, true)
const refreshToken = () => post("Users/refreshToken")

// Users Methods
const whoIAm = () => get("Users/WhoAmI")
const whoIamNewApi = () => get("users/whoiam", {}, true)
const getUsers = () => get("Users")
const getUsersNewApi = () => get(`users`, {}, true)
const getUsersByRole = idRol => get(`Users/GetByProfile?idRol=${idRol}`)
const getUsersByRoleNewApi = idRol => get(`users/profile/${idRol}`, {}, true)
const getUser = id => get(`Users/${id}`)
const getUserNewApi = id => get(`users/single/${id}`, {}, true)
const addUser = data => post("Users/Add", data)
const addUserNewApi = data => post("users/add", data, {}, true)
const updateUser = data => post("Users/Update", data)
const updateUserNewApi = data =>
  post(`users/edit/${data.idUsuario}`, data, {}, true)
const changeUserPassword = data => post("Users/ChangePassword", data)
const changeUserPasswordNewApi = data =>
  post("users/change-password", data, {}, true)
const activateDeactivateUser = data => post("Users/activateDeactivate", data)
const activateDeactivateUserNewApi = data =>
  post("users/activate-deactivate", data, {}, true)
const updateProfilePictureNewApi = file =>
  postFile("users/image", file, {}, true)
const postAcceptPrivacyTyc = data => post("Users/AceptTYCAndPrivacity", data)
const postAcceptPrivacyTycNewApi = data =>
  post("users/accept-tyc", data, {}, true)

const changePosition = data => post("users/update-position", data, {}, true)
const changeAboutMe = data => post("users/update-about-me", data, {}, true)

// Profiles Methods
const getProfiles = () => get("Profiles")
const getProfilesNewApi = () => get("users/profiles", {}, true)
const getProfile = id => get(`Profiles/${id}`)
const addProfile = data => post("Profiles/Add", data)
const updateProfile = data => post("Profiles/Update", data)

// Groups Methods
const getGroups = () => get("Groups")
const getGroupsNewApi = () => get("groups", {}, true)
const getGroup = id => get(`Groups/${id}`)
const getGroupNewApi = id => get(`groups/${id}`, {}, true)
const addGroup = data => post("Groups/Add", data)
const addGroupNewApi = data => post("groups/add", data, {}, true)
const updateGroup = data => post("Groups/Update", data)
const updateGroupNewApi = data =>
  post(`groups/edit/${data.idGrupo}`, data, {}, true)

const groupToTrash = payload =>
  post(`groups/trash/${payload.id}`, { trash: payload.trash }, {}, true)

// Positions Methods
const getPositions = () => get("Positions")
const getPositionsNewApi = () => get("positions/active", {}, true)
const getAllPositionsNewApi = () => get("positions/all", {}, true)
const getPosition = id => get(`Positions/${id}`)
const addPosition = data => post("Positions/Add", data)
const addPositionNewApi = data => post("positions/create", data, {}, true)
const updatePosition = data => post("Positions/Update", data)
const updatePositionNewApi = data =>
  post(`positions/update/${data.positionId}`, data, {}, true)

// Clients
const getClients = () => get("Clients")
const getClient = id => get(`Clients/${id}`)
const addClient = data => post("Clients/Add", data)
const updateClient = data => post("Clients/Update", data)
const getCustomersNewApi = active =>
  get(`customers/${active ? "active" : "all"}`, {}, true)
const getCustomerNewApi = id => get(`customers/${id}`, {}, true)
const addCustomerNewApi = data => post(`customers/create`, data, {}, true)
const updatecustomerNewApi = data =>
  post(`customers/update/${data.customerId}`, data, {}, true)

// Businesses
const getBusinesses = () => get("Business")
const getBusinessesNewApi = () => get("businesses/active", {}, true)
const getBusiness = id => get(`Business/${id}`)
const addBusiness = data => post("Business/Add", data)
const updateBusiness = data => post("Business/Update", data)

// Commercial areas
const getShoppingAreas = () => get("ZonaComercial")
const getShoppingAreasNewApi = () => get("shopping-areas/active", {}, true)
const getAllShoppingAreasNewApi = () => get("shopping-areas/all", {}, true)
const getShoppingArea = id => get(`ZonaComercial/${id}`)
const addShoppingArea = data => post("ZonaComercial/Add", data)
const addShoppingAreaNewApi = data =>
  post("shopping-areas/create", data, {}, true)
const updateShoppingArea = data => post("ZonaComercial/Update", data)
const updateShoppingAreaNewApi = data =>
  post(`shopping-areas/update/${data.shoppingAreaId}`, data, {}, true)

// Knowledge categories
const getKnowledgeCategories = () =>
  get("catalogs/knowledge-categories", {}, true)
const getAllKnowledgeCategories = () =>
  get("knowledge-categories/all", {}, true)
const getKnowledgeCategory = id => get(`KnowledgeCategory/${id}`)
const addKnowledgeCategory = data => post("KnowledgeCategories/Add", data)
const addKnowledgeCategoryNewApi = data =>
  post("knowledge-categories/create", data, {}, true)
const updateKnowledgeCategory = data => post("KnowledgeCategories/Update", data)
const updateKnowledgeCategoryNewApi = data =>
  post(
    `knowledge-categories/update/${data.knowledgeCategoryId}`,
    data,
    {},
    true
  )

// Skills
const getSkills = () => get("catalogs/abilities", {}, true)
const getSkill = id => get(`Abilities/${id}`)
const addSkill = data => post("Abilites/Add", data)
const updateSkill = data => post("Abilites/Update", data)

// Levels
const getLevels = () => get("catalogs/levels", {}, true)
const getLevel = id => get(`Levels/${id}`)
const addLevel = data => post("Levels/Add", data)
const updateLevel = data => post("Levels/Update", data)

// Course knowledges
const getCourseKnowledges = () => get("catalogs/knowledges", {}, true)
const getCourseKnowledge = id => get(`CourseKnowledge/${id}`)
const addCourseKnowledge = data => post("CourseKnowledge/Add", data)
const updateCourseKnowledge = data => post("CourseKnowledge/Update", data)

// Support material types
const getSupportMaterialTypes = () =>
  get("catalogs/support-material-types", {}, true)
const getAllSupportMaterialTypes = () =>
  get("support-material-types/all", {}, true)
const getSupportMaterialType = id => get(`SuportMaterialType/Get/${id}`)
const insertSupportMaterialType = data => post("SuportMaterialType/Add", data)
const addSupportMaterialTypeNewApi = data =>
  post("support-material-types/create", data, {}, true)
const updateSupportMaterialType = data =>
  post("SuportMaterialType/Update", data)
const updateSupportMaterialTypeNewApi = data =>
  post(
    `support-material-types/update/${data.supportMaterialTypeId}`,
    data,
    {},
    true
  )

// Courses
const getCourses = () => get("Courses")
const getCoursesNewApi = () => get("courses", {}, true)
const addCourse = data => post("courses/add", data, {}, true)
const getCourse = id => get(`courses/single/${id}`, {}, true)
const getCourseGroups = payload =>
  get(`courses/groups/all?courseId=${payload.idCourse}`, {}, true)
const updateCourse = data =>
  post(`courses/edit/${data.course.courseId}`, data, {}, true)
const updateCourseStatus = form =>
  post(`courses/change-status/${form.idCourse}`, form, {}, true)
const uploadImage = data => post("courses/image", data, {}, true)
const getCoursesByUser = idUser => get(`courses/my-courses`, {}, true)

const getCourseByUser = (idCourse, idUser) =>
  get(`courses/my-course/${idCourse}`, {}, true)
const startCourse = payload =>
  get(`courses/start/${payload.idCourse}`, {}, true)

const courseToTrash = payload =>
  post(`courses/trash/${payload.id}`, { trash: payload.trash }, {}, true)

// Lessons
const getLessons = idCourse => get(`lessons/course/${idCourse}`, {}, true)
const getOneLesson = idLesson => get(`lessons/single/${idLesson}`, {}, true)
const addLesson = data => post(`lessons/add`, data, {}, true)
const updateLesson = data =>
  post(`lessons/edit/${data.idLesson}`, data, {}, true)
const deleteLesson = id => post(`lessons/remove/${id}`, {}, {}, true)
const updateLessonPositions = data => post(`lessons/order`, data, {}, true)
const startLesson = payload =>
  get(`lessons/start/${payload.courseId}/${payload.lessonId}`, {}, true)
const finishLesson = payload =>
  get(`lessons/finish/${payload.courseId}/${payload.lessonId}`, {}, true)
const participantLesson = idCourse =>
  get(`lessons/participant/${idCourse}`, {}, true)

// Lesson blocks
const getBlocks = idLesson => get(`lessons/blocks/${idLesson}`, {}, true)
const getBlock = idBlock => get(`lessons/blocks/single/${idBlock}`, {}, true)
const addBlock = data => post(`lessons/blocks/add`, data, {}, true)
const updateBlock = data =>
  post(`lessons/blocks/edit/${data.blockId}`, data, {}, true)
const deleteBlock = id => post(`lessons/blocks/remove/${id}`, {}, {}, true)

// Support materials
const getSupportMaterials = idCourse =>
  get(`support-materials/all/${idCourse}`, {}, true)
const deleteSupportMaterial = id =>
  post(`support-materials/delete`, { id }, {}, true)
const addSupportMaterial = payload => {
  const form = payload.form
  const courseId = payload.request.courseId
  const title = payload.request.title
  const supportMaterialTypeId = payload.request.supportMaterialTypeId
  return postFile(
    `support-materials/file?courseId=${courseId}&title=${title}&supportMaterialTypeId=${supportMaterialTypeId}`,
    form,
    {},
    true
  )
}

// Quizzes
const getQuizzes = idCourse => get(`Quizzes/GetByCourse?idCourse=${idCourse}`)
const getQuizz = idQuizz => get(`Quizzes/${idQuizz}`)
const addQuizz = data => post(`Quizzes/Add`, data)
const updateQuizz = data => post(`Quizzes/Update`, data)
const getQuizzAnswers = idQuizz => get(`Quizzes/Answers/Get?id=${idQuizz}`)
const addQuizzAnswer = data => post(`Quizzes/Answers/Add`, data)
const updateQuizzAnswer = data => post(`Quizzes/Answers/Update`, data)
const deleteQuizzAnswer = id => post(`Quizzes/Answers/Delete`, id)

const getQuizzesNewApi = data => get(`quizzes/${data.lessonId}`, {}, true)
const addQuizzNewApi = data => post(`quizzes/create`, data, {}, true)
const updateQuizzNewApi = data => post(`quizzes/update`, data, {}, true)
const deleteQuizzNewApi = data => post(`quizzes/delete`, data, {}, true)
const addQuizzAnswerNewApi = data =>
  post("quizzes/answers/create", data, {}, true)
const updateQuizzAnswerNewApi = data =>
  post("quizzes/answers/update", data, {}, true)
const deleteQuizzAnswerNewApi = data =>
  post("quizzes/answers/delete", data, {}, true)
const getQuizzAnswerNewApi = data =>
  get(`quizzes/answers/${data.quizzId}`, {}, true)
const getQuizzAnswerUserNewApi = data =>
  get(`quizzes/answers/users/${data.quizzId}`, {}, true)
const addQuizzAnswerUserNewApi = data =>
  post("quizzes/answers/users/create", data, {}, true)

// Exam
const getExam = idCourse => get(`exams/single/${idCourse}`, {}, true)
const addExam = form => post(`exams/add`, form, {}, true)
const updateExam = form =>
  post(`exams/update/${form.courseExam}`, form, {}, true)

const startExam = form => post(`exams/start`, form, {}, true)
const finishExam = form => post(`exams/finish`, form, {}, true)
const getScore = form => post(`exams/assign-gamification-score`, form, {}, true)
const answerQuestionExam = form => post(`exams/answer`, form, {}, true)

// Question
const getQuestions = idExam => get(`exams/questions/all/${idExam}`, {}, true)
const getQuestion = idQuestion =>
  get(`exams/questions/single/${idQuestion}`, {}, true)
const addQuestion = form => post(`exams/questions/add`, form, {}, true)
const updateQuestion = form =>
  post(`exams/questions/update/${form.idExamQuestion}`, form, {}, true)
const deleteQuestion = id =>
  post(`exams/questions/delete`, { id: id }, {}, true)

// Answers
const getAnswers = idQuestion =>
  get(`Courses/Exams/Questions/Answers/All?idQuestion=${idQuestion}`)
const getAnswer = idAnswer =>
  get(`Courses/Exams/Questions/Answers/Single?idAnswer=${idAnswer}`)
const addAnswer = form => post(`Courses/Exams/Questions/Answers/Add`, form)
const addAnswersMulti = form => post(`exams/answers/multiadd`, form, {}, true)
const updateAnswer = form =>
  post(`exams/answers/update/${form.idQuestionAnswer}`, form, {}, true)
const deleteAnswer = id => post(`exams/answers/delete`, { id: id }, {}, true)

// Groups participants
const getGroupParticipants = idGroup =>
  get(`Groups/GetGroupParticipants?idGroup=${idGroup}`)
const getGroupParticipantsNewApi = idGroup =>
  get(`groups/participants/all?groupId=${idGroup}`, {}, true)
const getGroupCourses = payload => {
  const group = payload.idGroup ?? 0
  const course = payload.idCourse ?? 0
  return get(`Groups/GetGroupCourses?idGroup=${group}&idCourse=${course}`)
}
const getGroupCoursesNewApi = payload =>
  get(`groups/courses/all?groupId=${payload.idGroup}`, {}, true)
const insertGroupParticipant = data =>
  post(`groups/participants/add`, data, {}, true)
const deleteGroupParticipant = data =>
  post(`groups/participants/delete`, data, {}, true)
const insertGroupCourse = data => post(`groups/courses/add`, data, {}, true)
const deleteGroupCourse = data => post(`groups/courses/delete`, data, {}, true)
const resetGroupCourseApi = data =>
  get(`Courses/Reset/AllGroup?Group=${data.idGroup}&Course=${data.idCourse}`)
const resetUserGroupCourseApi = data =>
  get(
    `Courses/Reset/SingleUser?Group=${data.idGroup}&Course=${data.idCourse}&User=${data.idUser}`
  )
const resetGroupUserNewApi = data => post(`users/courses/reset`, data, {}, true)

// Polls
const getPolls = idCourse => get(`Poll/GetByCourse?idCourse=${idCourse}`)
const insertPoll = data => post(`Poll/Add`, data)
const updatePoll = data => post(`Poll/Update`, data)
const getPollAnswers = idPoll =>
  get(`Poll/Answers/GetByCourse?idCourse=${idPoll}`)
const insertPollAnswer = data => post(`Poll/Answers/Add`, data)
const insertMassivePollAnswer = data => post(`Poll/Answers/AddMassive`, data)
const updatePollAnswer = data => post(`Poll/Answers/Update`, data)
const deletePollAnswer = data => post(`Poll/Answers/Delete`, data)
const tryPoll = data =>
  post(
    `Poll/AddPollUserAnswerMassive
`,
    data
  )

// Survey (prev. Polls)
const getUserSurvey = data => get(`courses/survey/${data.courseId}`, {}, true)
const answerUserSurvey = data =>
  post(`courses/survey/${data.courseId}`, data, {}, true)

// Favourites
const addFav = idCourse => get(`courses/favourite/${idCourse}?fav=1`, {}, true)
const removeFav = idCourse =>
  get(`courses/favourite/${idCourse}?fav=0`, {}, true)

// Notes
const insertNote = data => post(`lessons/notes/add`, data, {}, true)
const deleteNote = id => post(`lessons/notes/remove`, { id: id }, {}, true)
const getNotes = idCourse => get(`lessons/notes/all/${idCourse}`, {}, true)

// Images
const addImage = file => postFile("courses/image", file, {}, true)

// Reports
const report1 = form => post(`Reports/Reporte1`, form)
const report1NewApi = form => post(`reports/report1`, form, {}, true)
const report2 = form => post(`Reports/Reporte2`, form)
const report2NewApi = form => post(`reports/report2`, form, {}, true)
const report3 = form => post(`Reports/Reporte3`, form)
const report3NewApi = form => post(`reports/report3`, form, {}, true)
const report4 = form => post(`Reports/Reporte4`, form)
const report4NewApi = form => post(`reports/report4`, form, {}, true)
const report5 = form => post(`Reports/Reporte5`, form)
const report5NewApi = form => post(`reports/report5`, form, {}, true)
const report6 = form => post(`Reports/Reporte6`, form)
const report6NewApi = form => post(`reports/report6`, form, {}, true)
const reportANewApi = form => post(`reports/reportA`, form, {}, true)
const reportb = form => post(`Reports/ReporteRCB`, form)
const reportBNewApi = form => post(`reports/reportB`, form, {}, true)
const report9NewApi = form => post(`reports/report9`, form, {}, true)
const report10NewApi = form => post(`reports/report10`, form, {}, true)
const reportSurveyNewApi = form => post(`reports/reportSurvey`, form, {}, true)

// New requests
const abilitiesWithGroup = form =>
  post(`users/abilitiesWithGroup`, form, {}, true)
const coursesWithGroup = form => post(`users/coursesWithGroup`, form, {}, true)
const resetCoursesWithGroup = form =>
  post(`users/resetCoursesWithGroup`, form, {}, true)

// Gamification
const getAllRules = () => get("gamifications/rules", {}, true)
const getRule = ruleId => get(`gamifications/rules/single/${ruleId}`, {}, true)
const updateRule = data =>
  post(`gamifications/rules/update/${data.ruleId}`, data, {}, true)
const getAllPeriods = () => get("gamifications/periods", {}, true)
const getPeriod = periodId =>
  get(`gamifications/periods/single/${periodId}`, {}, true)
const addPeriod = data => post(`gamifications/periods/add`, data, {}, true)
const updatePeriod = data =>
  post(`gamifications/periods/update/${data.periodId}`, data, {}, true)
const addPeriodRule = data =>
  post(`gamifications/periods/add-rule/${data.periodId}`, data, {}, true)
const removePeriodRule = data =>
  post(`gamifications/periods/delete-rule/${data.periodId}`, data, {}, true)
const addPeriodGroup = data =>
  post(`gamifications/periods/add-group/${data.periodId}`, data, {}, true)
const removePeriodGroup = ({ periodId, groupId }) =>
  post(`gamifications/periods/delete-group/${periodId}`, { groupId }, {}, true)
const addPeriodCourse = data =>
  post(`gamifications/periods/add-course/${data.periodId}`, data, {}, true)
const removePeriodCourse = ({ periodId, courseId }) =>
  post(
    `gamifications/periods/delete-course/${periodId}`,
    { courseId },
    {},
    true
  )
const getPeriodParticipants = periodId =>
  get(`gamifications/periods/detail-by-user/${periodId}`, {}, true)
const getPeriodClients = periodId =>
  get(`gamifications/periods/detail-by-client/${periodId}`, {}, true)
const getPeriodsParticipant = userId =>
  get(`gamifications/periods/participants/${userId}`, {}, true)
const getActivePeriodParticipants = () =>
  get(`gamifications/periods/active`, {}, true)

// Carousel
const getCarousels = () => get("carousels", {}, true)
const getActiveCarousels = () => get("carousels/active", {}, true)
const getCarousel = id => get(`carousels/single/${id}`, {}, true)
const addCarousel = data => post("carousels", data, {}, true)
const updateCarousel = data =>
  post(`carousels/single/${data.carouselId}`, data, {}, true)
const removeCarousel = data => post(`carousels/delete/${data.id}`, {}, {}, true)
const changeOrderCarousel = data => post(`carousels/order`, data, {}, true)

export {
  getLoggedInUser,
  isUserAuthenticated,
  postJwtLogin,
  postJwtLoginNewApi,
  refreshToken,
  lostPassword,
  lostPasswordNewApi,
  recoverPassword,
  recoverPasswordNewApi,
  getUsers,
  getUsersByRole,
  getUser,
  addUser,
  updateUser,
  changeUserPassword,
  activateDeactivateUser,
  postAcceptPrivacyTyc,
  getProfiles,
  getProfilesNewApi,
  getProfile,
  addProfile,
  updateProfile,
  getGroups,
  getGroupsNewApi,
  getGroup,
  getGroupNewApi,
  addGroup,
  addGroupNewApi,
  updateGroup,
  updateGroupNewApi,
  groupToTrash,
  resetGroupUserNewApi,
  getPositions,
  getPositionsNewApi,
  getAllPositionsNewApi,
  getPosition,
  addPosition,
  addPositionNewApi,
  updatePosition,
  updatePositionNewApi,
  getClients,
  getClient,
  addClient,
  getCustomersNewApi,
  getCustomerNewApi,
  addCustomerNewApi,
  updatecustomerNewApi,
  updateClient,
  whoIAm,
  getBusinesses,
  getBusinessesNewApi,
  getBusiness,
  addBusiness,
  updateBusiness,
  getShoppingAreas,
  getShoppingAreasNewApi,
  getAllShoppingAreasNewApi,
  getShoppingArea,
  addShoppingArea,
  addShoppingAreaNewApi,
  updateShoppingArea,
  updateShoppingAreaNewApi,
  getKnowledgeCategories,
  getAllKnowledgeCategories,
  getKnowledgeCategory,
  addKnowledgeCategory,
  addKnowledgeCategoryNewApi,
  updateKnowledgeCategory,
  updateKnowledgeCategoryNewApi,
  getLevels,
  getLevel,
  addLevel,
  updateLevel,
  getSkills,
  getSkill,
  addSkill,
  updateSkill,
  getSupportMaterialTypes,
  getAllSupportMaterialTypes,
  getSupportMaterialType,
  insertSupportMaterialType,
  addSupportMaterialTypeNewApi,
  updateSupportMaterialType,
  updateSupportMaterialTypeNewApi,
  getCourseKnowledges,
  getCourseKnowledge,
  addCourseKnowledge,
  updateCourseKnowledge,
  getCourses,
  getCoursesNewApi,
  getCoursesByUser,
  getCourseByUser,
  addCourse,
  updateCourse,
  updateCourseStatus,
  getCourse,
  getCourseGroups,
  startCourse,
  courseToTrash,
  uploadImage,
  getLessons,
  getOneLesson,
  addLesson,
  updateLesson,
  deleteLesson,
  participantLesson,
  updateLessonPositions,
  getBlocks,
  getBlock,
  addBlock,
  updateBlock,
  deleteBlock,
  addImage,
  getGroupParticipants,
  getGroupParticipantsNewApi,
  getGroupCourses,
  getGroupCoursesNewApi,
  resetGroupCourseApi,
  resetUserGroupCourseApi,
  insertGroupParticipant,
  deleteGroupParticipant,
  insertGroupCourse,
  deleteGroupCourse,
  getSupportMaterials,
  addSupportMaterial,
  deleteSupportMaterial,
  getQuizzes,
  getQuizz,
  addQuizz,
  updateQuizz,
  getQuizzAnswers,
  addQuizzAnswer,
  updateQuizzAnswer,
  deleteQuizzAnswer,
  getExam,
  addExam,
  updateExam,
  getQuestions,
  getQuestion,
  addQuestion,
  updateQuestion,
  deleteQuestion,
  getAnswers,
  getAnswer,
  addAnswer,
  addAnswersMulti,
  updateAnswer,
  deleteAnswer,
  getPolls,
  insertPoll,
  updatePoll,
  getPollAnswers,
  insertPollAnswer,
  insertMassivePollAnswer,
  updatePollAnswer,
  deletePollAnswer,
  tryPoll,
  addFav,
  removeFav,
  insertNote,
  deleteNote,
  getNotes,
  startLesson,
  finishLesson,
  startExam,
  finishExam,
  getScore,
  answerQuestionExam,
  report1,
  report1NewApi,
  report2,
  report2NewApi,
  report3,
  report3NewApi,
  report4,
  report4NewApi,
  report5,
  report5NewApi,
  report6,
  report6NewApi,
  reportANewApi,
  reportb,
  reportBNewApi,
  report9NewApi,
  report10NewApi,
  reportSurveyNewApi,
  abilitiesWithGroup,
  coursesWithGroup,
  resetCoursesWithGroup,
  /* ------ NEW API ------- */
  whoIamNewApi,
  getUsersNewApi,
  getUsersByRoleNewApi,
  getUserNewApi,
  addUserNewApi,
  updateUserNewApi,
  changeUserPasswordNewApi,
  activateDeactivateUserNewApi,
  updateProfilePictureNewApi,
  postAcceptPrivacyTycNewApi,
  getQuizzesNewApi,
  addQuizzNewApi,
  updateQuizzNewApi,
  deleteQuizzNewApi,
  addQuizzAnswerNewApi,
  updateQuizzAnswerNewApi,
  deleteQuizzAnswerNewApi,
  getQuizzAnswerNewApi,
  getQuizzAnswerUserNewApi,
  addQuizzAnswerUserNewApi,
  changePosition,
  changeAboutMe,
  getAllRules,
  getRule,
  updateRule,
  getAllPeriods,
  getPeriod,
  addPeriod,
  updatePeriod,
  addPeriodRule,
  removePeriodRule,
  addPeriodGroup,
  removePeriodGroup,
  addPeriodCourse,
  removePeriodCourse,
  getPeriodParticipants,
  getPeriodClients,
  getPeriodsParticipant,
  getActivePeriodParticipants,
  getCarousels,
  getActiveCarousels,
  getCarousel,
  addCarousel,
  updateCarousel,
  getUserSurvey,
  answerUserSurvey,
  removeCarousel,
  changeOrderCarousel,
}
