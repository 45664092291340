import React, { useState, useEffect, Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { Modal, Input, Row, Col, FormGroup, Label, Table } from "reactstrap"
import { AvField, AvForm, AvInput } from "availity-reactstrap-validation"
import Flatpickr from "react-flatpickr"
import {
  getLessons,
  addQuestion,
  editQuestion,
  addQuestionClean,
  editQuestionClean,
  editAnswer,
  removeQuestion,
  addAnsswers,
  editRule,
  editRuleClean,
  editPeriodClean,
  getGroups,
  getUsers,
  createPeriod,
  editPeriod,
  createPeriodClean,
  addGroupToPeriod,
  removeGroupToPeriod,
  getSinglePeriod,
  removeRuleToPeriod,
  removeRuleToPeriodClean,
  getSinglePeriodClean,
  getCourses,
  addCourseToPeriod,
  removeCourseToPeriod,
} from "store/actions"
import Select from "react-select"
import ImageUploadModal from "components/Common/ImageUploadModal"
import moment from "moment"
import RuleToPeriodModal from "./RuleToPeriodModal"
import UploadFileModal from "components/Carousel/UploadFileModal"

const PeriodFormModal = props => {
  const dispatch = useDispatch()
  const [insertMode, setInsertMode] = useState(true)
  const [uploadPdf, setUploadPdf] = useState(false)
  const [pdf, setPdf] = useState("")
  const [refresh, setRefresh] = useState(false)
  const [formError, setFormError] = useState("")
  const [status, setStatus] = useState(1)
  const [period, setPeriod] = useState(undefined)
  const [ruleToPeriod, setRuleToPeriod] = useState(undefined)
  const [groupsSelectedMulti, setGroupsSelectedMulti] = useState([])
  const [coursesSelectedMulti, setCoursesSelectedMulti] = useState([])
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [beneficiariesStartDate, setBeneficiariesStartDate] = useState(
    new Date()
  )
  const [beneficiariesEndDate, setBeneficiariesEndDate] = useState(new Date())
  const [responsiblesSelectedMulti, setResponsiblesSelectedMulti] = useState([])

  const {
    loading,
    done,
    error,
    createDone,
    removeRuleDone,
    createError,
    groups,
    users,
    insertedPeriod,
    getDone,
    courses,
  } = useSelector(state => ({
    loading: state.GamificationPeriods.loading,
    done: state.GamificationPeriods.editDone,
    error: state.GamificationPeriods.editError,
    createDone: state.GamificationPeriods.createDone,
    removeRuleDone: state.GamificationPeriods.removeRuleDone,
    createError: state.GamificationPeriods.createError,
    groups: state.GroupsList.groups,
    users: state.UsersList.users,
    insertedPeriod: state.GamificationPeriods.period,
    getDone: state.GamificationPeriods.getDone,
    courses: state.CoursesList.coursesForAdmin,
  }))

  let groupPeriod = []

  useEffect(() => {
    setPeriod(props.period)
  }, [props.period])

  useEffect(() => {
    if (period !== undefined) {
      setInsertMode(period.periodId === 0)
      setStartDate(period.startDate)
      setEndDate(period.endDate)
      setBeneficiariesStartDate(period.startBeneficiariesDate)
      setBeneficiariesEndDate(period.endBeneficiariesDate)
      setPdf(period.pdfPath === null ? "" : period.pdfPath)
      setStatus(
        period.status
          ? {
              label: "Activo",
              value: 1,
            }
          : {
              label: "Inactivo",
              value: 0,
            }
      )

      const user = users.find(user => user.userId === period.responsibleId)
      if (user !== undefined) {
        setResponsiblesSelectedMulti({
          label: `${user.firstName} ${user.lastName}`,
          value: user.userId,
        })
      }

      let groupsArray = []
      if (period.periodGroups !== undefined) {
        period.periodGroups.forEach(group => {
          const groupSingle = groups
            ? groups.find(g => g.idGrupo === group.groupId && group.status)
            : undefined
          if (groupSingle !== undefined) {
            groupsArray.push({
              label: `${groupSingle.nombreGrupo}`,
              value: groupSingle.idGrupo,
            })
          }
        })
      }
      setGroupsSelectedMulti(groupsArray)

      let coursesArray = []
      if (period.periodCourses !== undefined) {
        period.periodCourses.forEach(course => {
          const courseSingle = courses
            ? courses.find(g => g.courseId === course.courseId && course.status)
            : undefined
          if (courseSingle !== undefined) {
            coursesArray.push({
              label: `${courseSingle.courseName}`,
              value: courseSingle.courseId,
            })
          }
        })
      }
      setCoursesSelectedMulti(coursesArray)
    }
  }, [period, users, groups])

  useEffect(() => {
    dispatch(getGroups())
    dispatch(getCourses())
    dispatch(getUsers())
    return () => {
      setPeriod(undefined)
      setStartDate(new Date())
      setEndDate(new Date())
      setStatus(1)
      setResponsiblesSelectedMulti([])
      setBeneficiariesStartDate(new Date())
      setBeneficiariesEndDate(new Date())
      setGroupsSelectedMulti([])
      setCoursesSelectedMulti([])
    }
  }, [])

  const handleMulti = event => {
    event.forEach(evt => {
      const id = evt.value
      const exists = groupsSelectedMulti.some(selected => id === selected.value)
      if (!exists) handleAddGroup(id)
    })
    groupsSelectedMulti.forEach(sel => {
      const id = sel.value
      const exists = event.some(selected => id === selected.value)
      if (!exists) handleRemoveGroup(id)
    })
    setGroupsSelectedMulti(event)
  }

  const handleMultiCourses = event => {
    event.forEach(evt => {
      const id = evt.value
      const exists = coursesSelectedMulti.some(
        selected => id === selected.value
      )
      if (!exists) handleAddCourse(id)
    })
    coursesSelectedMulti.forEach(sel => {
      const id = sel.value
      const exists = event.some(selected => id === selected.value)
      if (!exists) handleRemoveCourse(id)
    })
    setCoursesSelectedMulti(event)
  }

  const groupOptions =
    groups !== undefined
      ? groups.map(group => {
          return {
            label: `${group.nombreGrupo}`,
            value: group.idGrupo,
          }
        })
      : []

  const courseOptions =
    groups !== undefined
      ? courses.map(course => {
          return {
            label: `${course.courseName}`,
            value: course.courseId,
          }
        })
      : []

  const responsiblesOG =
    users !== undefined
      ? users.map(user => {
          return {
            label: `${user.firstName} ${user.lastName}`,
            value: user.userId,
          }
        })
      : []

  const optionGroup = [
    {
      label: "Grupos",
      options: groupOptions,
    },
  ]

  const optionGroupCourses = [
    {
      label: "Cursos",
      options: courseOptions,
    },
  ]

  const responsiblesOtionGroup = [
    {
      label: "Responsable",
      options: responsiblesOG,
    },
  ]

  const statusOptionGroup = [
    {
      label: "Estatus",
      options: [
        { label: "Activo", value: 1 },
        { label: "Inactivo", value: 0 },
      ],
    },
  ]

  let periodRules = []

  if (period !== undefined) {
    if (period.periodRules !== undefined) {
      period.periodRules.forEach((periodRule, index) => {
        if (periodRule !== undefined) {
          if (periodRule.status === true) periodRules.push(periodRule)
        }
      })
    }
  }

  const handleAddGroup = id => {
    dispatch(
      addGroupToPeriod({
        groupId: id,
        periodId: period.periodId,
      })
    )
  }

  const handleRemoveGroup = id => {
    dispatch(
      removeGroupToPeriod({
        groupId: id,
        periodId: period.periodId,
      })
    )
  }

  const handleAddCourse = id => {
    dispatch(
      addCourseToPeriod({
        courseId: id,
        periodId: period.periodId,
      })
    )
  }

  const handleRemoveCourse = id => {
    dispatch(
      removeCourseToPeriod({
        courseId: id,
        periodId: period.periodId,
      })
    )
  }

  const handleResponsiblesSelectedMulti = selectedMulti => {
    setResponsiblesSelectedMulti(selectedMulti)
  }

  const handleStatus = event => {
    setStatus(event)
  }

  useEffect(() => {
    if (groupPeriod !== undefined && groups !== undefined) {
      let gpo = []
      groups.forEach((value, index) => {
        const group = groupPeriod.find(g => {
          return g.groupId === value.idGrupo
        })
        if (group !== undefined)
          gpo.push({
            label: `${value.nombreGrupo}`,
            value: value.idGrupo,
          })
      })
      setGroupsSelectedMulti(gpo)
    }
  }, [])

  const handleSubmit = async (event, errors, values) => {
    if (errors.length > 0) {
      setFormError(
        "Existen errores en el formulario. Por favor corrígelos para continuar"
      )
      return
    }
    if (startDate > endDate) {
      setFormError(
        "La fecha final del periodo no puede ser menor que la fecha inicial."
      )
      return
    }
    if (beneficiariesStartDate > beneficiariesEndDate) {
      setFormError(
        "La fecha final de la asignación de gandores no puede ser menor que la fecha inicial."
      )
      return
    }
    if (responsiblesSelectedMulti.length === 0) {
      setFormError("Debe seleccionar un responsable.")
      return
    }
    if (pdf === "") {
      setFormError("Debe cargar el archivo PDF.")
      return
    }

    setFormError("")
    const request = {
      periodId: period.periodId,
      name: values.rule,
      pdfPath: pdf,
      startDate: moment(startDate).toISOString(),
      endDate: moment(endDate).toISOString(),
      startBeneficiariesDate: moment(beneficiariesStartDate).toISOString(),
      endBeneficiariesDate: moment(beneficiariesEndDate).toISOString(),
      status: status.value,
      userId: responsiblesSelectedMulti.value,
    }

    if (insertMode) {
      dispatch(createPeriod(request))
    } else {
      dispatch(editPeriod(request))
    }
  }

  if (createDone) {
    setPeriod(period)
    dispatch(createPeriodClean())
    setRefresh(true)
  }

  if (done) {
    setRefresh(true)
    dispatch(editPeriodClean())
  }

  if (removeRuleDone) {
    dispatch(removeRuleToPeriodClean())
    dispatch(getSinglePeriod(period.periodId))
  }

  if (getDone) {
    setPeriod(insertedPeriod)
    dispatch(getSinglePeriodClean())
  }

  return (
    <Modal
      isOpen={props.show}
      backdrop={"static"}
      size="xl"
      id="staticBackdrop"
    >
      <AvForm className="form-horizontal" onSubmit={handleSubmit}>
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {insertMode ? "Registrar" : "Editar"} periodo
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              refresh ? props.onConfirm() : props.onCancel()
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          {formError !== "" && (
            <div className="alert alert-danger">{formError}</div>
          )}
          {error !== "" && <div className="alert alert-danger">error</div>}
          {refresh === true && (
            <div className="alert alert-success">
              Información guardada exitosamente. Pulse el botón de Cerrar para
              regresar a la pantalla anterior.
            </div>
          )}
          <Row className="mb-4">
            <Col md={12}>
              <AvField
                name="rule"
                label="Nombre del juego"
                className="form-control"
                placeholder="Ingrese el nombre del juego"
                value={
                  props.period !== undefined ? props.period.periodName : ""
                }
                type="text"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo es requerido",
                  },
                }}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md={3}>
              <div className="pt-1">Periodo del</div>
            </Col>
            <Col xs={3}>
              <Flatpickr
                name="startDate"
                value={moment(startDate).format("DD/MM/YYYY")}
                className="form-control d-block"
                placeholder="dd/mm/yyyy"
                onChange={date => {
                  setStartDate(date[0].toISOString())
                }}
                options={{
                  altInput: true,
                  altFormat: "d/m/Y",
                  dateFormat: "d/m/Y",
                }}
              />
            </Col>
            <Col xs={1} className="text-center">
              <div className="pt-1">al</div>
            </Col>
            <Col xs={3}>
              <Flatpickr
                name="endDate"
                value={moment(endDate).format("DD/MM/YYYY")}
                onChange={date => setEndDate(date[0].toISOString())}
                className="form-control d-block"
                placeholder="dd/mm/yyyy"
                options={{
                  altInput: true,
                  altFormat: "d/m/Y",
                  dateFormat: "d/m/Y",
                }}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md={4}>
              <div className="pt-1">Asignación de ganadores del</div>
            </Col>
            <Col xs={3}>
              <Flatpickr
                name="beneficiariesStartDate"
                onChange={date =>
                  setBeneficiariesStartDate(date[0].toISOString())
                }
                value={moment(beneficiariesStartDate).format("DD/MM/YYYY")}
                className="form-control d-block"
                placeholder="dd/mm/yyyy"
                options={{
                  altInput: true,
                  altFormat: "d/m/Y",
                  dateFormat: "d/m/Y",
                }}
              />
            </Col>
            <Col xs={1} className="text-center">
              <div className="pt-1">al</div>
            </Col>
            <Col xs={3}>
              <Flatpickr
                name="beneficiariesEndDate"
                onChange={date =>
                  setBeneficiariesEndDate(date[0].toISOString())
                }
                value={moment(beneficiariesEndDate).format("DD/MM/YYYY")}
                className="form-control d-block"
                placeholder="dd/mm/yyyy"
                options={{
                  altInput: true,
                  altFormat: "d/m/Y",
                  dateFormat: "d/m/Y",
                }}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={3}>* Archivo PDF:</Col>
            <Col md={9}>
              {pdf !== "" && pdf !== undefined ? (
                <a
                  href={pdf}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-primary"
                >
                  <i className="fa fa-file-image"></i> Ver PDF
                </a>
              ) : period !== undefined ? (
                period.pdfPath !== "" ? (
                  <a
                    href={period.pdfPath}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary"
                  >
                    <i className="fa fa-file-image"></i> Ver PDF
                  </a>
                ) : (
                  <button
                    type="button"
                    className="btn btn-light"
                    disabled={true}
                  >
                    <i className="fa fa-file-image"></i> Sin PDF
                  </button>
                )
              ) : (
                <button type="button" className="btn btn-light" disabled={true}>
                  <i className="fa fa-file-image"></i> Sin PDF
                </button>
              )}{" "}
              <button
                className="btn btn-dark"
                type="button"
                onClick={() => setUploadPdf(true)}
              >
                <i className="fa fa-upload"></i> Subir PDF
              </button>
            </Col>
          </Row>
          {!insertMode && (
            <Fragment>
              <Row>
                <Col lg={10} md={9}>
                  <p>Reglas a incluir en el juego</p>
                </Col>
                <Col lg={2} md={3} className="d-flex justify-content-end">
                  <button
                    type="button"
                    onClick={() =>
                      setRuleToPeriod({
                        ruleId: 0,
                        value: 0,
                      })
                    }
                    className="btn btn-sm btn-link"
                  >
                    <i className="fa fa-plus"></i> Añadir regla
                  </button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Table>
                    <thead>
                      <tr>
                        <th>Regla</th>
                        <th>Valor en puntos</th>
                        <th>Editar</th>
                        <th>Remover</th>
                      </tr>
                    </thead>
                    <tbody>
                      {periodRules.map((periodRule, index) => {
                        if (periodRule.status === false)
                          return (
                            <Fragment key={`period-rule-${index}`}></Fragment>
                          )
                        return (
                          <tr key={`period-rule-${index}`}>
                            <td>{periodRule.rule.ruleName}</td>
                            <td className="text-center">{periodRule.points}</td>
                            <td className="text-center">
                              <button
                                type="button"
                                onClick={() => setRuleToPeriod(periodRule)}
                                className="btn btn-sm btn-link"
                              >
                                <i className="fa fa-edit"></i>
                              </button>
                            </td>
                            <td className="text-center">
                              <button
                                type="button"
                                onClick={() =>
                                  dispatch(
                                    removeRuleToPeriod({
                                      ruleId: periodRule.rule.ruleId,
                                      periodId: period.periodId,
                                    })
                                  )
                                }
                                className="btn btn-sm btn-link"
                              >
                                <i className="fa fa-times"></i>
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                      {periodRules.length <= 0 && (
                        <tr>
                          <td colSpan={4} className="text-center">
                            -- Sin reglas agregadas --
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Fragment>
          )}
          <Row className="mb-3">
            <Col md={3}>
              <Label for="responsible" className="pt-1">
                * Responsable:
              </Label>
            </Col>
            <Col md={9}>
              <Select
                name="responsible"
                value={responsiblesSelectedMulti}
                isMulti={false}
                onChange={event => {
                  handleResponsiblesSelectedMulti(event)
                }}
                options={responsiblesOtionGroup}
                classNamePrefix="select2-selection"
              />
            </Col>
          </Row>
          {!insertMode && (
            <Row className="mb-3">
              <Col md={3}>
                <Label for="groups" className="pt-1">
                  Grupos participantes:
                </Label>
              </Col>
              <Col md={9}>
                <Select
                  name="groups"
                  value={groupsSelectedMulti}
                  isMulti={true}
                  onChange={event => {
                    handleMulti(event)
                  }}
                  options={optionGroup}
                  classNamePrefix="select2-selection"
                />
              </Col>
            </Row>
          )}
          {!insertMode && (
            <Row className="mb-3">
              <Col md={3}>
                <Label for="courses" className="pt-1">
                  Cursos participantes:
                </Label>
              </Col>
              <Col md={9}>
                <Select
                  name="courses"
                  value={coursesSelectedMulti}
                  isMulti={true}
                  onChange={event => {
                    handleMultiCourses(event)
                  }}
                  options={optionGroupCourses}
                  classNamePrefix="select2-selection"
                />
              </Col>
            </Row>
          )}
          <Row className="mb-3">
            <Col md={3}>
              <Label for="status" className="pt-1">
                * Estatus
              </Label>
            </Col>
            <Col md={9}>
              <Select
                name="status"
                value={status}
                isMulti={false}
                onChange={event => {
                  handleStatus(event)
                }}
                options={statusOptionGroup}
                classNamePrefix="select2-selection"
              />
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          {refresh === true && (
            <button
              type="button"
              className={`btn btn-light`}
              onClick={() => props.onConfirm()}
            >
              {"Salir"}
            </button>
          )}
          <button type="submit" className={`btn btn-secondary`}>
            {"Guardar"}
          </button>
        </div>
      </AvForm>
      {uploadPdf && (
        <UploadFileModal
          show={true}
          onCancel={() => setUploadPdf(false)}
          onConfirm={file => {
            setUploadPdf(false)
            setPdf(file)
          }}
          image={false}
        />
      )}
      <RuleToPeriodModal
        show={ruleToPeriod !== undefined}
        periodId={period === undefined ? 0 : period.periodId}
        rule={ruleToPeriod}
        onConfirm={() => {
          setRuleToPeriod(undefined)
          dispatch(getSinglePeriod(period.periodId))
        }}
        onCancel={() => setRuleToPeriod(undefined)}
      />
      <style jsx="true">{`
        .noNorder {
          border: 0 !important;
        }
      `}</style>
    </Modal>
  )
}

PeriodFormModal.propTypes = {
  show: PropTypes.bool,
  period: PropTypes.object,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default PeriodFormModal
